import * as FS from './FreeSemigroup';
/**
 * @category model
 * @since 2.2.7
 */
// eslint-disable-next-line @typescript-eslint/prefer-as-const
export var required = 'required';
/**
 * @category model
 * @since 2.2.7
 */
// eslint-disable-next-line @typescript-eslint/prefer-as-const
export var optional = 'optional';
/**
 * @category constructors
 * @since 2.2.7
 */
export var leaf = function (actual, error) {
  return {
    _tag: 'Leaf',
    actual: actual,
    error: error
  };
};
/**
 * @category constructors
 * @since 2.2.7
 */
export var key = function (key, kind, errors) {
  return {
    _tag: 'Key',
    key: key,
    kind: kind,
    errors: errors
  };
};
/**
 * @category constructors
 * @since 2.2.7
 */
export var index = function (index, kind, errors) {
  return {
    _tag: 'Index',
    index: index,
    kind: kind,
    errors: errors
  };
};
/**
 * @category constructors
 * @since 2.2.7
 */
export var member = function (index, errors) {
  return {
    _tag: 'Member',
    index: index,
    errors: errors
  };
};
/**
 * @category constructors
 * @since 2.2.7
 */
export var lazy = function (id, errors) {
  return {
    _tag: 'Lazy',
    id: id,
    errors: errors
  };
};
/**
 * @category constructors
 * @since 2.2.9
 */
export var wrap = function (error, errors) {
  return {
    _tag: 'Wrap',
    error: error,
    errors: errors
  };
};
/**
 * @category destructors
 * @since 2.2.7
 */
export var fold = function (patterns) {
  var f = function (e) {
    switch (e._tag) {
      case 'Leaf':
        return patterns.Leaf(e.actual, e.error);
      case 'Key':
        return patterns.Key(e.key, e.kind, e.errors);
      case 'Index':
        return patterns.Index(e.index, e.kind, e.errors);
      case 'Member':
        return patterns.Member(e.index, e.errors);
      case 'Lazy':
        return patterns.Lazy(e.id, e.errors);
      case 'Wrap':
        return patterns.Wrap(e.error, e.errors);
    }
  };
  return f;
};
/**
 * @category instances
 * @since 2.2.7
 */
export function getSemigroup() {
  return FS.getSemigroup();
}