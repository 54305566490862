import { apFirst as apFirst_, apSecond as apSecond_ } from './Apply';
import { chainFirst as chainFirst_ } from './Chain';
import { identity, pipe as pipeFromFunctionModule } from './function';
export function map(F) {
  return function (f) {
    return function (fa) {
      return F.map(fa, f);
    };
  };
}
export function contramap(F) {
  return function (f) {
    return function (fa) {
      return F.contramap(fa, f);
    };
  };
}
export function mapWithIndex(F) {
  return function (f) {
    return function (fa) {
      return F.mapWithIndex(fa, f);
    };
  };
}
export function ap(F) {
  return function (fa) {
    return function (fab) {
      return F.ap(fab, fa);
    };
  };
}
export function chain(F) {
  return function (f) {
    return function (fa) {
      return F.chain(fa, f);
    };
  };
}
export function bimap(F) {
  return function (f, g) {
    return function (fea) {
      return F.bimap(fea, f, g);
    };
  };
}
export function mapLeft(F) {
  return function (f) {
    return function (fea) {
      return F.mapLeft(fea, f);
    };
  };
}
export function extend(F) {
  return function (f) {
    return function (wa) {
      return F.extend(wa, f);
    };
  };
}
export function reduce(F) {
  return function (b, f) {
    return function (fa) {
      return F.reduce(fa, b, f);
    };
  };
}
export function foldMap(F) {
  return function (M) {
    var foldMapM = F.foldMap(M);
    return function (f) {
      return function (fa) {
        return foldMapM(fa, f);
      };
    };
  };
}
export function reduceRight(F) {
  return function (b, f) {
    return function (fa) {
      return F.reduceRight(fa, b, f);
    };
  };
}
export function reduceWithIndex(F) {
  return function (b, f) {
    return function (fa) {
      return F.reduceWithIndex(fa, b, f);
    };
  };
}
export function foldMapWithIndex(F) {
  return function (M) {
    var foldMapWithIndexM = F.foldMapWithIndex(M);
    return function (f) {
      return function (fa) {
        return foldMapWithIndexM(fa, f);
      };
    };
  };
}
export function reduceRightWithIndex(F) {
  return function (b, f) {
    return function (fa) {
      return F.reduceRightWithIndex(fa, b, f);
    };
  };
}
export function alt(F) {
  return function (that) {
    return function (fa) {
      return F.alt(fa, that);
    };
  };
}
export function filter(F) {
  return function (predicate) {
    return function (fa) {
      return F.filter(fa, predicate);
    };
  };
}
export function filterMap(F) {
  return function (f) {
    return function (fa) {
      return F.filterMap(fa, f);
    };
  };
}
export function partition(F) {
  return function (f) {
    return function (fa) {
      return F.partition(fa, f);
    };
  };
}
export function partitionMap(F) {
  return function (f) {
    return function (fa) {
      return F.partitionMap(fa, f);
    };
  };
}
export function filterWithIndex(F) {
  return function (predicate) {
    return function (fa) {
      return F.filterWithIndex(fa, predicate);
    };
  };
}
export function filterMapWithIndex(F) {
  return function (f) {
    return function (fa) {
      return F.filterMapWithIndex(fa, f);
    };
  };
}
export function partitionWithIndex(F) {
  return function (f) {
    return function (fa) {
      return F.partitionWithIndex(fa, f);
    };
  };
}
export function partitionMapWithIndex(F) {
  return function (f) {
    return function (fa) {
      return F.partitionMapWithIndex(fa, f);
    };
  };
}
export function promap(F) {
  return function (f, g) {
    return function (fbc) {
      return F.promap(fbc, f, g);
    };
  };
}
export function compose(F) {
  return function (ea) {
    return function (ab) {
      return F.compose(ab, ea);
    };
  };
}
var isFunctor = function (I) {
  return typeof I.map === 'function';
};
var isContravariant = function (I) {
  return typeof I.contramap === 'function';
};
var isFunctorWithIndex = function (I) {
  return typeof I.mapWithIndex === 'function';
};
var isApply = function (I) {
  return typeof I.ap === 'function';
};
var isChain = function (I) {
  return typeof I.chain === 'function';
};
var isBifunctor = function (I) {
  return typeof I.bimap === 'function';
};
var isExtend = function (I) {
  return typeof I.extend === 'function';
};
var isFoldable = function (I) {
  return typeof I.reduce === 'function';
};
var isFoldableWithIndex = function (I) {
  return typeof I.reduceWithIndex === 'function';
};
var isAlt = function (I) {
  return typeof I.alt === 'function';
};
var isCompactable = function (I) {
  return typeof I.compact === 'function';
};
var isFilterable = function (I) {
  return typeof I.filter === 'function';
};
var isFilterableWithIndex = function (I) {
  return typeof I.filterWithIndex === 'function';
};
var isProfunctor = function (I) {
  return typeof I.promap === 'function';
};
var isSemigroupoid = function (I) {
  return typeof I.compose === 'function';
};
var isMonadThrow = function (I) {
  return typeof I.throwError === 'function';
};
/** @deprecated */
export function pipeable(I) {
  var r = {};
  if (isFunctor(I)) {
    r.map = map(I);
  }
  if (isContravariant(I)) {
    r.contramap = contramap(I);
  }
  if (isFunctorWithIndex(I)) {
    r.mapWithIndex = mapWithIndex(I);
  }
  if (isApply(I)) {
    r.ap = ap(I);
    r.apFirst = apFirst_(I);
    r.apSecond = apSecond_(I);
  }
  if (isChain(I)) {
    r.chain = chain(I);
    r.chainFirst = chainFirst_(I);
    r.flatten = r.chain(identity);
  }
  if (isBifunctor(I)) {
    r.bimap = bimap(I);
    r.mapLeft = mapLeft(I);
  }
  if (isExtend(I)) {
    r.extend = extend(I);
    r.duplicate = r.extend(identity);
  }
  if (isFoldable(I)) {
    r.reduce = reduce(I);
    r.foldMap = foldMap(I);
    r.reduceRight = reduceRight(I);
  }
  if (isFoldableWithIndex(I)) {
    r.reduceWithIndex = reduceWithIndex(I);
    r.foldMapWithIndex = foldMapWithIndex(I);
    r.reduceRightWithIndex = reduceRightWithIndex(I);
  }
  if (isAlt(I)) {
    r.alt = alt(I);
  }
  if (isCompactable(I)) {
    r.compact = I.compact;
    r.separate = I.separate;
  }
  if (isFilterable(I)) {
    r.filter = filter(I);
    r.filterMap = filterMap(I);
    r.partition = partition(I);
    r.partitionMap = partitionMap(I);
  }
  if (isFilterableWithIndex(I)) {
    r.filterWithIndex = filterWithIndex(I);
    r.filterMapWithIndex = filterMapWithIndex(I);
    r.partitionWithIndex = partitionWithIndex(I);
    r.partitionMapWithIndex = partitionMapWithIndex(I);
  }
  if (isProfunctor(I)) {
    r.promap = promap(I);
  }
  if (isSemigroupoid(I)) {
    r.compose = compose(I);
  }
  if (isMonadThrow(I)) {
    var fromOption = function (onNone) {
      return function (ma) {
        return ma._tag === 'None' ? I.throwError(onNone()) : I.of(ma.value);
      };
    };
    var fromEither = function (ma) {
      return ma._tag === 'Left' ? I.throwError(ma.left) : I.of(ma.right);
    };
    var fromPredicate = function (predicate, onFalse) {
      return function (a) {
        return predicate(a) ? I.of(a) : I.throwError(onFalse(a));
      };
    };
    var filterOrElse = function (predicate, onFalse) {
      return function (ma) {
        return I.chain(ma, function (a) {
          return predicate(a) ? I.of(a) : I.throwError(onFalse(a));
        });
      };
    };
    r.fromOption = fromOption;
    r.fromEither = fromEither;
    r.fromPredicate = fromPredicate;
    r.filterOrElse = filterOrElse;
  }
  return r;
}
/**
 * Use [`pipe`](https://gcanti.github.io/fp-ts/modules/function.ts.html#pipe) from `function` module instead.
 *
 * @since 2.0.0
 * @deprecated
 */
export var pipe = pipeFromFunctionModule;