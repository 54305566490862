export function chainFirst(M) {
  var tapM = tap(M);
  return function (f) {
    return function (first) {
      return tapM(first, f);
    };
  };
}
/** @internal */
export function tap(M) {
  return function (first, f) {
    return M.chain(first, function (a) {
      return M.map(f(a), function () {
        return a;
      });
    });
  };
}
export function bind(M) {
  return function (name, f) {
    return function (ma) {
      return M.chain(ma, function (a) {
        return M.map(f(a), function (b) {
          var _a;
          return Object.assign({}, a, (_a = {}, _a[name] = b, _a));
        });
      });
    };
  };
}