/**
 * @since 2.2.0
 */
export function memoize(f) {
  var cache = new Map();
  return function (a) {
    if (!cache.has(a)) {
      var b = f(a);
      cache.set(a, b);
      return b;
    }
    return cache.get(a);
  };
}
// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------
var typeOf = function (x) {
  return x === null ? 'null' : typeof x;
};
/**
 * @internal
 */
export var intersect_ = function (a, b) {
  if (a !== undefined && b !== undefined) {
    var tx = typeOf(a);
    var ty = typeOf(b);
    if (tx === 'object' || ty === 'object') {
      return Object.assign({}, a, b);
    }
  }
  return b;
};